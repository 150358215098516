var aria = require('./lib/utils');

// Drop list lang
// ==============
const drop_config = {
  dropBtns: document.querySelectorAll('[data-js-dropbtn]'),
  dropdowns: document.querySelectorAll('[data-js-dropdown]'),
  linkActive: "is-active",
  dropActive: "is-open",
}

// Dropdown
if (0 != drop_config.dropBtns.length && 0 != drop_config.dropdowns.length) {

  // Create open dropdown event
  const openDrop = new CustomEvent('open.drop', { bubbles: true, });
  const closeDrop = new CustomEvent('close.drop', { bubbles: true, });

  const openDropDown = (elem) => {
    // Link
    const relatedBtn = document.querySelector('[data-js-dropbtn="' + elem.dataset.jsDropdown + '"]');
    relatedBtn.setAttribute("aria-expanded", "true");
    relatedBtn.classList.add(drop_config.linkActive);
    if (relatedBtn.dataset.jsHideLabel) {
      relatedBtn.textContent = relatedBtn.dataset.jsHideLabel
    }

    // panel
    elem.setAttribute("aria-hidden", "false");
    elem.classList.add(drop_config.dropActive);
    // If list :
    // Set focus on first elem
    const firstChild = elem.querySelector('[role="option"]');
    firstChild && firstChild.focus();
  }

  const closeDropDown = (elem) => {
    // Link
    const relatedBtn = document.querySelector('[data-js-dropbtn="' + elem.dataset.jsDropdown + '"]');
    relatedBtn.setAttribute("aria-expanded", "false");
    relatedBtn.classList.remove(drop_config.linkActive);
    relatedBtn.focus();
    if (relatedBtn.dataset.jsHideLabel) {
      relatedBtn.textContent = relatedBtn.dataset.jsShowLabel
    }

    // Panel
    elem.setAttribute("aria-hidden", "true");
    elem.classList.remove(drop_config.dropActive);
  }


  [].forEach.call(drop_config.dropBtns, function (elem) {
    const relatedDropId = elem.dataset.jsDropbtn;
    const relatedDrop = document.querySelector('[data-js-dropdown="' + relatedDropId + '"]');
    elem.addEventListener('click', (e) => {
      e.preventDefault();
      // Open related dropdown
      if (!elem.classList.contains(drop_config.linkActive)) {
        // Open panel
        relatedDrop.dispatchEvent(openDrop);
      } else {
        // Close dropdown
        relatedDrop.dispatchEvent(closeDrop);
      }
    })
    elem.addEventListener('keyup', (e) => {
      e.preventDefault();
      var key = e.which || e.keyCode;
      switch (key) {
        case aria.KeyCode.DOWN:
          openDropDown(relatedDrop);
          break;
      }
    });
  });

  [].forEach.call(drop_config.dropdowns, function (elem) {
    elem.addEventListener('open.drop', (e) => {
      e.preventDefault();
      openDropDown(elem);
    })
    elem.addEventListener('close.drop', (e) => {
      e.preventDefault();
      closeDropDown(elem);
    })
    elem.addEventListener('keyup', (e) => {
      e.preventDefault();
      var key = e.which || e.keyCode;
      let currentFocused = elem.querySelector('[role="option"]:focus');
      switch (key) {
        case aria.KeyCode.DOWN:
          let nextElem = currentFocused.parentNode.nextElementSibling;
          if (null != nextElem) {
            nextElem.querySelector('[role="option"]').focus();
          }
          break;
        case aria.KeyCode.UP:
          let prevElem = currentFocused.parentNode.previousElementSibling;
          if (null != prevElem) {
            prevElem.querySelector('[role="option"]').focus();
          }
          break;
        case aria.KeyCode.ESC:
          // Close lang
          closeDropDown(elem);
          break;
        case aria.KeyCode.TAB:
          if (e.shishiftKey) {
            closeDropDown(elem);
          }
          break;
      }
    })
  });
}
